import React, { ReactElement } from "react";
import Seo from "../../../components/Seo/seo";
import {
  CaseStudy,
  HeroSection,
  ColumnsSection,
  Banner,
  CaseStudyDetails,
} from "../../../components/CaseStudy";
import MedreferDetails from "../../../assets/imgs/case-study/medrefer/Details.svg";
import ApptFormIcon from "../../../assets/imgs/case-study/medrefer/icons/ApptFormIcon.svg";
import InboxIcon from "../../../assets/imgs/case-study/medrefer/icons/InboxIcon.svg";
import MedicalIcon from "../../../assets/imgs/case-study/medrefer/icons/MedicalIcon.svg";
import SecurityIcon from "../../../assets/imgs/case-study/medrefer/icons/SecurityIcon.svg";
import { graphql } from "gatsby";

export default function captio({ data }): ReactElement {
  const {
    heroImage,
    heroLogo,
    howItWorks,
    challange,
    solution,
    inbox,
    apptform,
    security,
    medical,
  } = data;

  return (
    <div className="container">
      <Seo title={"Medrefer"} />
      <CaseStudy
        title="Appointment scheduling"
        body={
          <>
            <HeroSection
              heroTitle="Revolution of the referral process and comprehensive patient care"
              heroTitleStyle={{ marginBottom: "50px" }}
              heroDescription={[
                "Instantaneous doctor-2-doctor and doctor-2-hospital appointment scheduling",
              ]}
              heroDescriptionStyle={{ paddingTop: "350px" }}
              heroImage={heroImage.childImageSharp.fixed}
              heroLogo={heroLogo.childImageSharp.fixed}
            />
            <ColumnsSection
              customClass="medrefer"
              columns={[
                {
                  title: "How it works",
                  description: [
                    "Captio, a video-chat with real-time transcription for easy communication. Designed for people with hearing impairments.",
                  ],
                  image: howItWorks.childImageSharp.fixed,
                },
                {
                  title: "What was the challenge?",
                  description: [
                    "On the side of the referrer and the provider (more than 10.000 phone calls per month in larger hospitals). Delay in diagnosis/treatment Due to patient's inability to sche-dule the appointment. Due to lack of technical options to schedule an appointment automatically directly from GP office. Phones, letters and a lack of acceptance for internet based platforms due to data protection concerns.",
                  ],
                  image: challange.childImageSharp.fixed,
                },
                {
                  title: "Solution",
                  description: [
                    "Developing the online, highly secure scheduling platform which simplifies the referral process by making instantaneous doctor-2-doctor and doctor-2-hospital appointment scheduling possible while keeping the patient in the loop.",
                  ],
                  image: solution.childImageSharp.fixed,
                },
              ]}
            />
            <ColumnsSection
              customClass="white-bg reverse flex objectFitNone"
              columns={[
                {
                  icon: <InboxIcon style={{ marginLeft: "-10px" }} />,
                  subtitle: "Inbox",
                  description: [
                    "Quickly accessible patient data. Intuitive design, aligned with commonly used e-mail software. Excellent appointment handling capabilities thanks to filters, tags and statuses. Integration with external calendars",
                  ],
                  image: inbox.childImageSharp.fixed,
                },
                {
                  icon: <ApptFormIcon style={{ marginLeft: "-10px" }} />,
                  subtitle: "Appointment form",
                  description: [
                    "Easy and intuitive appointment request form to speed up data entry. Automatic feedback on provider's availability hours. Integration possibilities with HIS and EMR systems.",
                  ],
                  image: apptform.childImageSharp.fixed,
                },
                {
                  icon: <SecurityIcon style={{ marginLeft: "-10px" }} />,
                  subtitle: "Security and Data protection",
                  description: [
                    "Secure access for authorized GP and Hospital staff. Secure data transmission and storage using proven communication and encryption technologies. Strict adherence to data protection regulations (GDPR).",
                  ],
                  image: security.childImageSharp.fixed,
                },
                {
                  icon: <MedicalIcon style={{ marginLeft: "-10px" }} />,
                  subtitle: "Medical questionaries",
                  description: [
                    "Secure access for authorized GP and Hospital staff. Secure data transmission and storage using proven communication and encryption technologies. Strict adherence to data protection regulations (GDPR).",
                  ],
                  image: medical.childImageSharp.fixed,
                },
              ]}
            />
            <Banner
              title="What makes us different?"
              subtitle="In addition to the high level of medical expertise and excellent technical skills the founder team has at their disposal a broadly diversified network of international healthcare and IT professionals focused on innovation and digitalization in medicine."
            />
            <CaseStudyDetails
              title="Medrefer in details"
              image={<MedreferDetails />}
            />
          </>
        }
      />
    </div>
  );
}

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "imgs/case-study/medrefer/MedreferHero.png" }
    ) {
      childImageSharp {
        fixed(width: 931, height: 647, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    heroLogo: file(
      relativePath: { eq: "imgs/case-study/medrefer/MedreferLogo.png" }
    ) {
      childImageSharp {
        fixed(width: 248, height: 37, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    howItWorks: file(
      relativePath: { eq: "imgs/case-study/medrefer/howitworks.png" }
    ) {
      childImageSharp {
        fixed(width: 528, height: 459, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    challange: file(
      relativePath: { eq: "imgs/case-study/medrefer/challange.png" }
    ) {
      childImageSharp {
        fixed(width: 523, height: 517, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    solution: file(
      relativePath: { eq: "imgs/case-study/medrefer/solution.png" }
    ) {
      childImageSharp {
        fixed(width: 524, height: 421, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    inbox: file(relativePath: { eq: "imgs/case-study/medrefer/inbox.png" }) {
      childImageSharp {
        fixed(width: 539, height: 310, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    apptform: file(
      relativePath: { eq: "imgs/case-study/medrefer/apptform.png" }
    ) {
      childImageSharp {
        fixed(width: 528, height: 301, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    security: file(
      relativePath: { eq: "imgs/case-study/medrefer/security.png" }
    ) {
      childImageSharp {
        fixed(width: 539, height: 310, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    medical: file(
      relativePath: { eq: "imgs/case-study/medrefer/medical.png" }
    ) {
      childImageSharp {
        fixed(width: 528, height: 302, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
